import React from "react";

export const User = () => {
  return (
    <svg
      className="profile-icon"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>User</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1270.000000, -47.000000)" stroke="#140041" strokeWidth="1.6">
          <g transform="translate(70.000000, 17.000000)">
            <g transform="translate(1201.000000, 31.000000)">
              <circle cx="12.8" cy="12.8" r="12.8"></circle>
              <path d="M12.5,16.8 C16.0022339,16.8 18.943797,19.5160137 19.7690733,23.1879728 C17.7731214,24.7124107 15.3768993,25.6 12.8,25.6 C10.0038358,25.6 7.42040397,24.5549369 5.33020691,22.7866568 C6.27372542,19.31956 9.12680441,16.8 12.5,16.8 Z"></path>
              <circle cx="12.8" cy="11.2" r="4.8"></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
