import React, { FunctionComponent, useEffect } from "react";
import RichTextImage from "web-common/src/components/RichTextImage";
import RichText from "web-common/src/components/RichText";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ImageInterface, PortableText } from "web-common/src/types/SanityTypes";
import "./styles.scss";
import Container from "react-bootstrap/Container";

interface ImageTextBlockInterface {
  data?: {
    image: ImageInterface;
    text: PortableText;
    textPosition: string;
    imageType: boolean;
  };
  imageAsset?: {
    _rawImage: ImageInterface;
    image?: ImageInterface;
    _rawText: PortableText;
    text?: PortableText;
    textPosition: string;
    imageType: boolean;
  };
  title?: string;
}

const ImageTextBlock: FunctionComponent<ImageTextBlockInterface> = ({ data, imageAsset, title }) => {
  const imageType = data?.imageType || imageAsset?.imageType;
  const textPosition = data?.textPosition || imageAsset?.textPosition;
  const image = (data?.image || imageAsset?._rawImage || imageAsset?.image) as ImageInterface;
  const text = (data?.text || imageAsset?._rawText || imageAsset?.text) as PortableText;
  const imageTypeClass = imageType === true ? "about-us-image-text" : "image-text";

  const handleScroll = () => {
    if (typeof window !== `undefined`) {
      const pageHash = window.location.hash
      if (pageHash == "#bom-para-o-planeta") {
        const element = document.getElementById("planet");
        element?.scrollIntoView({behavior: "smooth"})
      } 
    }
  }


  const getHeading = () => {
    // @ts-ignore
    if (data?.text[0].children[0].text.toLowerCase().includes("nossas latas são 100% recicláveis")) {
      return "recycled"
    } else if (data?.text[0]) {
      //@ts-ignore
      if (data?.text[0].children[0].text.toLowerCase().includes("bom para o planeta")) {
        return "planet"
      }
    } else {
      return undefined
    }
  }

  const renderBlockOrder = () => {
    if (textPosition === "left") {
      return (
        <>
          {title && <h2>{title}</h2>}
          <Col lg={6} md={12} className="order-lg-2">
            <RichTextImage data={image} type={imageTypeClass} />
          </Col>
          <Col lg={6} md={12} className="order-lg-1" data-testid="text-left">
            <RichText data={text} />
          </Col>
        </>
      );
    }
    return (
      <>
        {title && <h2>{title}</h2>}
        <Col lg={6} md={12}>
          <RichTextImage data={image} type={imageTypeClass} />
        </Col>
        <Col lg={6} md={12} data-testid="text-right">
          <RichText data={text} />
        </Col>
      </>
    );
  };
  return (
    <div className="image-text-block" id={getHeading()}>
      {handleScroll()}
      {imageAsset ? (
        <Container fluid>
          <Row className="image-asset-block">{renderBlockOrder()}</Row>
        </Container>
      ) : (
        <Row>{renderBlockOrder()}</Row>
      )}
    </div>
  );
};

export default ImageTextBlock;