import * as React from "react";
import { useContext } from "react";
import SocialBlock from "web-common/src/components/SocialBlock";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "web-common/src/components/Link";
import "./styles.scss";
import { FunctionComponent } from "react";
import ExternalLink from "web-common/src/components/ExternalLink";
import AdChoices from "web-common/src/components//AdChoices";
import LanguageToggle from "web-common/src/components//LanguageToggle";
import CountryIdentifier from "web-common/src/components//CountryIdentifier";
import { SanityNavigation, SanityNavItem, SanitySocialMediaInformation, InternalLink } from "web-common/src/types/SanityTypes";
import { LocalizedContext } from "web-common/src/services/LocalizedContextService";
import { graphql, useStaticQuery } from "gatsby";
import { identifyCountry } from "web-common/src/utils/identifiers";
import { useSiteMetadata } from "web-common/src/hooks/useSiteMetadata";
import UnileverIcon from "web-common/src/components/Footer/UnileverIcon";
import Accessibility from "web-common/src/components/Accessibility";

export interface FooterInterface {
  data: {
    sanitySocialMediaInformation?: SanitySocialMediaInformation;
    sanityLocationSelector?: InternalLink;
    sanityFooter?: SanityNavigation;
    sanityLabels?: {
      footerLegalDisclaimer?: string;
      adChoicesText?: string;
      countrySelectorLabel?: string;
    };
    pageTranslations: {
      language: string;
      urlPath: string;
    }[];
  };
}

const Footer: FunctionComponent<FooterInterface> = ({
  data: { sanityFooter, sanitySocialMediaInformation, sanityLocationSelector, sanityLabels, pageTranslations }
}) => {
  const socialLinks = sanitySocialMediaInformation?.socialLinks || [];
  const navItems = sanityFooter?.navItems || [];
  const { language, market} = useContext(LocalizedContext);
  const { locale } = useSiteMetadata(language);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, country] = locale.split("_");
  const query = useStaticQuery(graphql`
    query Footer2 {
      allSanityLanguage {
        nodes {
          name
          market
        }
      }
    }
  `);

  const filteredLanguages = query.allSanityLanguage.nodes.filter((node: any) => node.market === market);

  const renderFooterNav = () => {
    return navItems.map((item: SanityNavItem) => {
      return item.navL1.externalLink ? (
        <li key={item.navL1.name}>
          {/* <ExternalLinkIcon /> */}
          <ExternalLink link={item.navL1.externalLink} name={item.navL1.name} markKey={item.navL1.name} noopener={true} nofollow={true} hasIcon = {false} />
        </li>
      ) : (
        <li key={item.navL1.name}>
          {item.navL1.landingPage?.slug && (
            <Link _id={item.navL1.landingPage?._id} to={item.navL1.landingPage.slug.current}>
              {item.navL1.name}
            </Link>
          )}
        </li>
      );
    });
  };

  return (
    <footer id="footer" className="footer" data-testid="footer">
      <Container fluid className="inner">
        <div className="social-block-container">
          <SocialBlock socialLinks={socialLinks.sort()} />
        </div>
        <Row xs={2} className="justify-content-between no-gutters">
          <Col className="col-auto">
            <ul>
              {renderFooterNav()}
              {sanityLabels?.adChoicesText && (
                <li>
                  <AdChoices label={sanityLabels.adChoicesText} />
                </li>
              )}
              <li>
                <a className="external-link hands" href={"https://pslz.in/unilever"} rel="noreferrer">

                  {" "}
                  Atendimento em Libras
                  <Accessibility openNewWindow={true} />
                </a>
              </li>
              {sanityLocationSelector && language && (
                <li>
                  <CountryIdentifier
                    location={sanityLocationSelector}
                    locationText={identifyCountry(country, language)}
                    locationHover={sanityLabels?.countrySelectorLabel}
                  />
                </li>
              )}
              {language && (
                <li>
                  <LanguageToggle
                      languages={filteredLanguages.map((node: { name: string }) => node.name)}
                    currentLanguage={language}
                    translations={pageTranslations}
                  />
                </li>
              )}
            </ul>
          </Col>
          <Col className="align-self-end col-auto text-center">
            <div className="logo-position">
              <UnileverIcon linkToUnilever = {sanityFooter?.unileverLink as string} />
            </div>
            <p>&copy; {new Date().getFullYear()} Unilever</p>
          </Col>
        </Row>
        {sanityLabels?.footerLegalDisclaimer && (
          <Row className="legal-disclaimer">{sanityLabels.footerLegalDisclaimer}</Row>
        )}
      </Container>
    </footer>
  );
};

export default Footer;
