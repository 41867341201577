import React, { FunctionComponent, useContext, useEffect, useReducer, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import SearchIcon from "web-common/src/components/Header/searchIcon";
import Logo from "web-common/src/components/Header/logo";
import { Link } from "web-common/src/components/Link";
import sanityImageUrlBuilder from "web-common/src/utils/imageUrlBuilder";
import { useSiteMetadata } from "web-common/src/hooks/useSiteMetadata";
import {
  InternalLink,
  SanityNavigation,
  SanityNavItem,
  SanityNavLink,
  ImageInterface
} from "web-common/src/types/SanityTypes";
import Container from "react-bootstrap/Container";
import { Close } from "web-common/src/images/icons/close";
import { Burger } from "web-common/src/images/icons/burger";
import "./styles.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { LocalizedContext } from "web-common/src/services/LocalizedContextService";
import ProfileToggle from "./profileToggle";

type Heading = {
  heading?: string;
};

type CtaLabel = {
  ctaLabel?: string;
};

export interface HeaderInterface {
  data: {
    sanityArticleFaq?: InternalLink & CtaLabel;
    sanityLogin?: InternalLink & Heading;
    sanityHomePage?: InternalLink & {
      _rawLogo: ImageInterface;
    };
    sanitySignUp?: InternalLink & Heading;
    sanityNewsletterSignUp?: InternalLink;
    sanityNewsletterSignUpEpsilon?: InternalLink;
    sanityLogOut?: InternalLink & Heading;
    sanitySearch?: InternalLink;
    sanitySearchItems?: InternalLink;
    sanityProfile?: InternalLink &
      Heading & {
        loggedInUserGreeting?: string;
        loggedOutUserGreeting?: string;
      };
    sanityHeader?: SanityNavigation;
  };
}

type MenuItemClasses = {
  isOpen: boolean;
  displayClass: string;
  stateClass: string;
  svgPosition?: string;
};

type MenuItemClassesMap = {
  [menuItem: string]: MenuItemClasses;
};

const closedMenuItemState = {
  isOpen: false,
  displayClass: "none",
  stateClass: "menuItem-close"
};

const openMenuItemState = {
  isOpen: true,
  displayClass: "block",
  stateClass: "menuItem-open"
};

const AccessibilityNav = () => {
  const { skipToContent, skipToFooter } = useContext(LocalizedContext).sanityAccessibility || {};

  return (
    <ul className="accessibility-nav">
      <li>
        <a className="skip-link" href="#mainContent">
          {skipToContent || "skip to content"}
        </a>
      </li>
      <li>
        <a className="skip-link" href="#footer">
          {skipToFooter || "skip to footer"}
        </a>
      </li>
    </ul>
  );
};

const Header: FunctionComponent<HeaderInterface> = ({
  data: {
    sanityArticleFaq,
    sanityLogOut,
    sanityLogin,
    sanityHeader,
    sanityHomePage,
    sanityProfile,
    sanitySearch,
    sanitySearchItems,
    sanitySignUp,
    sanityNewsletterSignUp,
    sanityNewsletterSignUpEpsilon
  }
}) => {
  const { sanityId, sanityDataset, searchV2 } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const [width, setWidth] = useState(991);
  const [hoverImage, setHoverImage] = useState<string>("");
  const [menuExpand, setMenuExpand] = useState<boolean>(false);
  const [expandIcon, setExpandIcon] = useState<string>(menuExpand ? "-" : "+");
  const { sanityAccessibility } = useContext(LocalizedContext);
  const { showContent, hideContent } = sanityAccessibility || {};

  const navItems = sanityHeader?.navItems || [];

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
  });

  const mobileMenuNavToggle = () => {
    menuExpand ? setMenuExpand(false) : setMenuExpand(true);
  };

  useEffect(() => {
    menuExpand ? setExpandIcon("-") : setExpandIcon("+");
  }, [menuExpand]);

  const initMenuItemClasses = (initialArg: MenuItemClasses) => {
    const initialState: MenuItemClassesMap = {};
    navItems.forEach(navItem => {
      initialState[navItem.navL1.name] = initialArg;
    });
    return initialState;
  };

  const reducer = (state: MenuItemClassesMap, selectedMenuItem: string) => {
    const newState: MenuItemClassesMap = {};
    Object.assign(newState, state);

    // Toggle the selected menu item
    if (newState[selectedMenuItem].isOpen) {
      newState[selectedMenuItem] = closedMenuItemState;
    } else {
      newState[selectedMenuItem] = openMenuItemState;
    }
    Object.keys(newState).forEach(menuItem => {
      // Close all other menu items
      if (menuItem !== selectedMenuItem) {
        newState[menuItem] = closedMenuItemState;
      }
    });

    return newState;
  };

  const [menuItemOpenStates, toggleMenuItemOpenState] = useReducer(reducer, closedMenuItemState, initMenuItemClasses);

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
  });

  const renderMobileNav = () => {
    return navItems.map(navItem => {
      const page = navItem.navL1.landingPage;
      const link = page?.slug?.current || "/";
      const removeBorder = navItems.length - 1 ? { border: "none" } : undefined;
      const path = navItem?.navL1?.path;
      return (
        <Nav.Link as="span" key={navItem.navL1.name} style={removeBorder}>
          {navItem.navL2 && navItem.navL2.length > 0 ? (
            <Dropdown>
              <>
                <Dropdown.Toggle
                  className="nav-toggle nav-heading-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={menuExpand}
                  data-testid={`${link}-toggle`}
                  onMouseDown={mobileMenuNavToggle as (event: React.MouseEvent<HTMLInputElement>) => void}
                  onBlur={() => setMenuExpand(false)}
                >
                  <span data-testid={`${link}-link-mobile`} className="nav-heading">
                    {`${navItem.navL1.name}`}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="-12 -6 24 24"
                    width="20"
                    height="15"
                    type="image/svg+xml"
                    aria-expanded="false"
                    className={menuExpand !== true ? "nav-heading-dropdown-svg" : "nav-heading-dropdown-svg is-open"}
                    aria-hidden="true"
                  >
                    <polyline points="-10,0 0,10 10,0" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="menu-container">{renderMobileNavItem(navItem, link)}</div>
                </Dropdown.Menu>
              </>
            </Dropdown>
          ) : (
            <Dropdown>
              <Link _id={page?._id} to={path ? path : link} data-testid={`${link}-link-mobile`} className="nav-heading">
                {navItem.navL1.name}
              </Link>
            </Dropdown>
          )}
        </Nav.Link>
      );
    });
  };

  const renderMobileNavItem = (navItem: SanityNavItem, parentPage: string | undefined) => {
    return navItem.navL2?.map((navLink, index) => {
      const page = navLink.landingPage;
      const link = navLink.landingPage?.slug?.current;
      const path = navLink.path || `${parentPage}/${link}`;
      return (
        <div data-testid={`subnav-${index + 1}`} key={navLink.name}>
          <Link key={`${link}-link-${index + 1}`} _id={page?._id} to={path} className="nav-items">
            {navLink.name}
          </Link>
        </div>
      );
    });
  };

  const renderDesktopNav = () => {
    return navItems.map(navItem => {
      const navL1Page = navItem.navL1.landingPage;
      const linkL1 = navL1Page?.slug?.current || "";
      const path = navItem?.navL1?.path;
      return (
        <React.Fragment key={`${linkL1}`}>
          <style key={`${linkL1}-style`}>
            {`
              @media (min-width: 768px) {
                .${linkL1} a.nav-items:hover,
                .${linkL1} a.nav-items.active,
                .${linkL1} button.nav-items.hover,
                .${linkL1} button.nav-items.active {
                  color: ${navL1Page?.main ? navL1Page?.main.sectionColor.value : "var(--blue-bright-color)"};
                }
                .${linkL1} a:hover,
                .${linkL1} a.active,
                .${linkL1} button:hover,
                .${linkL1} button.active {
                  text-decoration: underline 5px ${
                    navL1Page?.main ? navL1Page?.main.sectionColor.value : "var(--blue-bright-color)"
                  };
                }
                .${linkL1} .active + .nav-toggle {
                  text-decoration: underline 5px ${
                    navL1Page?.main ? navL1Page?.main.sectionColor.value : "var(--blue-bright-color)"
                  };
                }
              }
            `}
          </style>
          <Nav.Link as="li" key={`${linkL1}-primary-nav`}>
            <Dropdown className={linkL1} key={`${linkL1}-dropdown`}>
              {navItem.navL2 && navItem.navL2.length > 0 ? (
                <>
                  <Link
                    _id={navL1Page?._id}
                    to={path ? path : `${linkL1}`}
                    data-testid={`${linkL1}-link-desktop`}
                    className="nav-heading dropdown-toggle nav-toggle"
                    hidden
                  >
                    {navItem.navL1.name}
                  </Link>
                  <button
                    tabIndex={0}
                    onClick={() => toggleMenuItemOpenState(navItem.navL1.name)}
                    data-testid={`${linkL1}-link-desktop`}
                    className="nav-heading dropdown-toggle nav-toggle dropdown-arrow"
                    aria-expanded={menuItemOpenStates[navItem.navL1.name].isOpen !== true ? "false" : "true"}
                    key={`${linkL1}-link`}
                    onKeyDown={(event: React.KeyboardEvent) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        toggleMenuItemOpenState(navItem.navL1.name);
                      }
                    }}
                  >
                    {navItem.navL1.name}
                    {
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-12 -6 24 24"
                        width="18"
                        height="13"
                        type="image/svg+xml"
                        aria-expanded="false"
                        role="img"
                        className={menuItemOpenStates[navItem.navL1.name].isOpen !== true ? "" : "is-open"}
                      >
                        <title>
                          {menuItemOpenStates[navItem.navL1.name].isOpen !== true ? 'show' : 'hide'}
                        </title>
                        <polyline points="-10,0 0,10 10,0" />
                      </svg>
                    }
                  </button>
                </>
              ) : (
                <Link
                  _id={navL1Page?._id}
                  to={path ? path : `${linkL1}`}
                  data-testid={`${linkL1}-link-desktop`}
                  className="nav-heading dropdown-toggle nav-toggle"
                  key={`${linkL1}-link`}
                >
                  {navItem.navL1.name}
                </Link>
              )}
              {navItem.navL2 && navItem.navL2.length > 0 && (
                <div
                  id={`dropdown-${navItem.navL1.name}`}
                  className="dropdown-menu"
                  style={{ display: menuItemOpenStates[navItem.navL1.name].displayClass }}
                  key={`${linkL1}-secondary-nav`}
                  onBlur={(e: React.FocusEvent) => {
                    if (!e.currentTarget.contains(e.relatedTarget)) {
                      toggleMenuItemOpenState(navItem.navL1.name);
                    }
                  }}
                >
                  <div className={menuItemOpenStates[navItem.navL1.name].stateClass} key={`${linkL1}-image`}>
                    {renderL2NavImages(navItem)}
                  </div>
                  <ul key={`${linkL1}-container-item`} className="nav-container-item">
                    {renderDesktopL2NavItems(navItem.navL2 || [], linkL1)}
                  </ul>
                </div>
              )}
            </Dropdown>
          </Nav.Link>
        </React.Fragment>
      );
    });
  };

  const renderL2NavImages = (navItem: SanityNavItem) => {
    return navItem.navL2?.map((navLink, index) => {
      const link = navLink.landingPage?.slug?.current;
      return (
        <React.Fragment key={`${link}`}>
          {hoverImage === navLink.name && navLink._rawImage ? (
            <div key={`${link}-header-image`} className="header-image" data-testid={`nav-image-${index + 1}`}>
              <figure key={`${link}-figure`}>
                <source
                  key={`${link}-source`}
                  media="screen and (min-width: 768px)"
                  srcSet={`${urlBuilder
                    .image(navLink._rawImage)
                    .auto("format")
                    .quality(80)
                    .width(260)
                    .height(150)
                    .format("webp")
                    .url()}`}
                />
                <img
                  key={`${link}-image`}
                  src={
                    urlBuilder
                      .image(navLink._rawImage)
                      .auto("format")
                      .quality(80)
                      .width(260)
                      .height(150)
                      .format("webp")
                      .url() as string
                  }
                  alt={navLink._rawImage.alt}
                  loading={"lazy"}
                />
              </figure>
            </div>
          ) : null}
        </React.Fragment>
      );
    });
  };

  const renderDesktopL2NavItems = (navLinks: SanityNavLink[], parentPage: string | undefined) => {
    return navLinks.map((navLink, index) => {
      const page = navLink.landingPage;
      const link = navLink.landingPage?.slug?.current;
      const navPath = navLink.path || `${parentPage}/${link}`;
      const mouseOver = () => {
        setHoverImage(navLink.name);
      };
      const mouseOut = () => {
        setHoverImage("");
      };

      return (
        <li
          key={`${link}`}
          onMouseOver={mouseOver as React.MouseEventHandler<HTMLLIElement>}
          onMouseOut={mouseOut as React.MouseEventHandler<HTMLLIElement>}
        >
          <Link key={`${link}-link-${index + 1}`} _id={page?._id} to={navPath} className="nav-items">
            {navLink.name}
          </Link>
        </li>
      );
    });
  };

  return (
    <header data-testid="header" className="header">
      {width >= 992 ? (
        <div className="d-none d-lg-block" data-testid="desktop-nav">
          <Container fluid>
            <AccessibilityNav />
            <div className="d-flex justify-content-between align-items-center nav-container">
              <div data-testid="logo-link" className="desktop-logo">
                {sanityHomePage && (
                  <Link _id={sanityHomePage._id} to={sanityHomePage.slug.current}>
                    <Logo logoImage={sanityHomePage._rawLogo} />
                  </Link>
                )}
              </div>
              <div className="desktop-nav">
                <nav>
                  <Nav as="ul">{renderDesktopNav()}</Nav>
                </nav>
              </div>
              <div className="desktop-utilities">
                <span data-testid="search-link">
                  {sanitySearch && !searchV2 ? (
                    <Link _id={sanitySearch._id} to={sanitySearch.slug.current}>
                      <SearchIcon />
                    </Link>
                  ) : sanitySearchItems && searchV2 ? (
                    <Link _id={sanitySearchItems._id} to={sanitySearchItems.slug.current}>
                      <SearchIcon />
                    </Link>
                  ) : (
                    <SearchIcon />
                  )}
                </span>
                <div className="profile-show" style={{ width: "auto" }}>
                  <ProfileToggle
                    data={{
                      sanityArticleFaq,
                      sanityLogOut,
                      sanityLogin,
                      sanityProfile,
                      sanitySignUp,
                      sanityNewsletterSignUp,
                      sanityNewsletterSignUpEpsilon
                    }}
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
      ) : (
        <div className="d-lg-none" data-testid="mobile-nav">
          <Navbar expand="lg">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" data-testid="header-toggle" className="mobile-burger">
              <Burger />
            </Navbar.Toggle>

            <Navbar.Collapse id="responsive-navbar-nav" data-testid="navbar-collapse">
              <div className="closing-container">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="mobile-x">
                  <Close />
                </Navbar.Toggle>
              </div>

              <Nav className="mobile-nav">{renderMobileNav()}</Nav>

              <div className="search-mobile" data-testid="search-link">
                {sanitySearch && !searchV2 ? (
                  <Link _id={sanitySearch._id} to={sanitySearch.slug.current}>
                    <SearchIcon />
                  </Link>
                ) : sanitySearchItems && searchV2 ? (
                  <Link _id={sanitySearchItems._id} to={sanitySearchItems.slug.current}>
                    <SearchIcon />
                  </Link>
                ) : (
                  <SearchIcon />
                )}
              </div>
            </Navbar.Collapse>

            <div data-testid="logo-link" className="mobile-logo">
              {sanityHomePage && (
                <Link _id={sanityHomePage._id} to={sanityHomePage.slug.current}>
                  <Logo logoImage={sanityHomePage._rawLogo} />
                </Link>
              )}
            </div>

            <div className="profile-show">
              <ProfileToggle
                data={{
                  sanityArticleFaq,
                  sanityLogOut,
                  sanityLogin,
                  sanityProfile,
                  sanitySignUp,
                  sanityNewsletterSignUp,
                  sanityNewsletterSignUpEpsilon
                }}
              />
            </div>
          </Navbar>
        </div>
      )}
    </header>
  );
};
export default Header;
