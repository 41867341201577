import React, { FunctionComponent, useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ReactComponent as Instagram } from "web-common/src/images/icons/instagram-sketch.svg";
import { useSiteMetadata } from "web-common/src/hooks/useSiteMetadata";
import { useInView } from "react-intersection-observer";
import { MultiLineHeadline } from "../../../../../common/src/types/SanityTypes";
import ExternalLink from "../../../../../common/src/components/ExternalLink";
import "./styles.scss";
import "web-common/src/styles/utilities/_sizes.scss";
import { LocalizedContext } from "../../../../../common/src/services/LocalizedContextService";
import { event41 } from "web-common/src/analytics/event41";
import { event42 } from "web-common/src/analytics/event42";
import { event54 } from "web-common/src/analytics/event54";
import { event53 } from "web-common/src/analytics/event53";
import { event52 } from "web-common/src/analytics/event52";

type Image = {
  url: string;
};
type Post = {
  objectid: string;
  images: Image[];
  text: string;
  uid: string;
};

export interface SocialWallInterface {
  folder: string;
  socialLink: {
    ctaURL: string;
    ctaLabel: string;
  }[];
  headline: MultiLineHeadline;
}

const SocialWall: FunctionComponent<SocialWallInterface> = ({ folder, socialLink, headline }) => {

  const { sanityMiappi } = useContext(LocalizedContext);
  const { miappiClientId, youtubeApiIframe } = useSiteMetadata();
  const [posts, setPosts] = useState([] as Array<Post>);

  const [socialWallRef, socialWallinView] = useInView({
    triggerOnce: true
  });

  useEffect(() => {
    fetch(`https://data.miappi.com/clients/v1/${miappiClientId}/feed?folders=${folder}&limit=3`)
      .then(response => response.json())
      .then(response => response.data)
      .then(data => setPosts(data));
  }, [folder, miappiClientId]);

  useEffect(() => {
    posts.map((post: Post) => {
      if (post.source === "twitterstream") {
        const vid = document.getElementById("id-" + post.objectid) as HTMLMediaElement;
        let previousPosition = 0;
        const title = post.media.text;
        const videoAssetId = post.media.url;

        const onPlay = () => {
          event41(title, videoAssetId);
          // If the user pauses, we don't want to track subsequent play events
          vid?.removeEventListener("play", onPlay);
        };
        const onEnded = () => {
          event42(title, videoAssetId);
          // If the user watches the video again, track it
          vid?.addEventListener("play", onPlay);
        };
        const onTimeUpdate = () => {
          let currentPosition = 0;
          if (vid?.currentTime && vid?.duration) {
            currentPosition = vid.currentTime / vid.duration;
          }
          if (currentPosition >= 0.75 && previousPosition < 0.75) {
            event54(title, videoAssetId);
          }
          if (currentPosition >= 0.5 && previousPosition < 0.5) {
            event53(title, videoAssetId);
          }
          if (currentPosition >= 0.25 && previousPosition < 0.25) {
            event52(title, videoAssetId);
          }
          previousPosition = currentPosition;
        };

        vid?.addEventListener("play", onPlay);
        vid?.addEventListener("ended", onEnded);
        vid?.addEventListener("timeupdate", onTimeUpdate);

        return () => {
          vid?.removeEventListener("play", onPlay);
          vid?.removeEventListener("ended", onEnded);
          vid?.removeEventListener("timeupdate", onTimeUpdate);
        };
      }
    });

    const videoIDs = [];
    posts.map((post: Post) => {
      if (post.source === "youtubestream") {
        videoIDs.push(post.objectid);
      }
    });
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = youtubeApiIframe;

      for (let i = 0; i < videoIDs.length; i++) {
        window.onYouTubeIframeAPIReady = loadVideo(videoIDs[i]);
      }
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      for (let i = 0; i < videoIDs.length; i++) {
        window.onYouTubeIframeAPIReady = loadVideo(videoIDs[i]);
      }
    }
  });

  const loadVideo = (id: string) => {
    if (typeof window.YT !== "undefined" || typeof window.YT.Player !== "undefined") {
      new window.YT.Player(`id-${id}`, {
        videoId: id,
        events: {
          onStateChange: onPlayerStateChange
        }
      });
    }
  };
  const onPlayerStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof window.YT !== "undefined" || typeof window.YT.Player !== "undefined") {
      if (event.data == YT.PlayerState.PLAYING) {
        const videoInfo = event.target.getVideoData();
        event41(videoInfo.title, videoInfo.video_id);
      }
      if (event.data == YT.PlayerState.ENDED) {
        const videoInfo = event.target.getVideoData();
        event42(videoInfo.title, videoInfo.video_id);
      }
    }
  };

  const renderImageItems = () => {
    return posts !== []
      ? posts.map((post: Post, index: number) => {
          if (posts) {
            const cardPosition = "post_" + String(index);
            //Check video source
            if (post.source === "twitterstream") {
              return (
                <>
                  <li className={cardPosition + " link-position"} key={post.objectid}>
                    <video
                      id={"id-" + post.objectid}
                      width="396"
                      height="396"
                      controls
                      muted
                      className={"utl-element-w—100"}
                    >
                      <source src={post.media.url} type="video/mp4" />
                    </video>
                  </li>
                </>
              );
            }
            if (post.source === "youtubestream") {
              return (
                <>
                  <li className={cardPosition + " link-position"} key={post.objectid}>
                    <iframe
                      id={"id-" + post.objectid}
                      src={post.media.url + "?enablejsapi=1"}
                      width="396"
                      height="396"
                      className={"utl-element-w—100 utl-no-border"}
                    />
                  </li>
                </>
              );
            }
          }
        })
      : null;
  };

  const renderSocialLinks = () => {
    return socialLink.map(item => {
      return (
        <li key={item.ctaLabel}>
          <ExternalLink link={item.ctaURL} name={item.ctaLabel} noopener={true} nofollow={false} hasIcon = {false} />
        </li>
      );
    });
  };

  return (
    <section className="social-wall" data-testid="social-wall">
      <Container fluid>
        <Row>
          <Col>
            <div className="copy-and-posts">
              <div className="social-copy">
                <h2
                  aria-label={
                    headline.primaryText + " " + (headline.secondaryText || "") + " " + (headline.tertiaryText || "")
                  }
                >
                  {headline.primaryText}{" "}
                  <span aria-hidden="true">{headline.secondaryText && headline.secondaryText}</span>
                  {headline.tertiaryText && headline.tertiaryText}
                </h2>
                <ul>{socialLink && renderSocialLinks()}</ul>
                <Instagram />
              </div>
              <ul className="posts" data-testid="social-wall-image" ref={socialWallRef}>
                {socialWallinView && renderImageItems()}
              </ul>
              {sanityMiappi && (
                <div className="miappi">
                  <ExternalLink
                    link={"https://miappi.com/"}
                    name={sanityMiappi.miappiTag}
                    noopener={true}
                    nofollow={false}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SocialWall;
